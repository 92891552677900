<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          <!--<v-flex xs2>
                        <v-menu
                                ref="menu1"
                                v-model="beginDateMenu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        v-model="formattedMonth"
                                        :label="$t('global.month')"
                                        v-bind="attrs"
                                        v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="date" type="month" color="primary"  @input="beginDateMenu = false"></v-date-picker>
                        </v-menu>
                    </v-flex>-->
          <v-spacer />
          <id-button @click="onRefreshData">
            <template #icon>
              <v-icon left>mdi-refresh</v-icon>
            </template>
            {{ $t("buttons.refresh") }}
          </id-button>
        </v-card-title>

        <v-data-table
          :headers="table.headers"
          :items="table.data"
          :options.sync="table.options"
          :server-items-length="table.totalItems"
          single-select
          class="elevation-1"
          :loading="table.loading"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
        >
          <template slot="item" slot-scope="row">
            <tr :key="row.item.id">
              <td class="text-xs-left no-wrap">
                <employee-profile :employee="row.item.leaveRequest.employee" />
              </td>

              <td>
                <v-list-item-content>
                  <v-list-item-title>
                    {{
                      $store.state.auth.language === "tr"
                        ? row.item.leaveRequest.leaveType.name
                        : row.item.leaveRequest.leaveType.englishName
                    }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ getTotalLeaveDay(row.item.leaveRequest.leaveRequestDays) }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </td>
              <td>
                {{ row.item.leaveRequest.beginLeaveRequestDay.date | formattedDate }}
                {{ getIsHourlyLeaveBeginTime(row.item.leaveRequest.leaveRequestDays) }}
              </td>
              <td>
                {{ row.item.leaveRequest.endLeaveRequestDay.date | formattedDate }}
                {{ getIsHourlyLeaveEndTime(row.item.leaveRequest.leaveRequestDays) }}
              </td>
              <td>
                {{ row.item.leaveRequest.requestDateTime | formattedDate }}
              </td>
              <td class="no-wrap">
                <v-btn small dark depressed color="primary" @click.stop="onClickLeaveApproval(row.item)" class="mr-2">
                  {{ $t("buttons.action") }}
                </v-btn>
              </td>
              <td>
                <v-btn small dark depressed color="primary" @click.stop="onClickLeaveCancel(row.item)" class="mr-2">
                  {{ $t("buttons.cancel_leave") }}</v-btn
                >
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <approval-confirm-dialog
      v-model="dialog.status"
      :data.sync="dialog.data"
      @close="onCloseDialog"
      @success="onSuccessApproval"
    />
    <leave-cancel-dialog
      v-model="cancelDialog.status"
      :data.sync="cancelDialog.data"
      @close="onCloseLeaveDialog"
      @success="onSuccessLeaveCancel"
    />
  </v-row>
</template>

<script>
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description shifts Component Logic
   * @date 25.07.2020
   */
  import { LEAVE_APPROVAL } from "../query";

  export default {
    name: "LeaveApproval",
    components: {
      "approval-confirm-dialog": () => import("./components/confirm-dialog"),
      "leave-cancel-dialog": () => import("./components/leave-cancel-dialog")
    },
    data: vm => ({
      cancelDialog: {
        status: false,
        data: null
      },
      dialog: {
        status: false,
        data: null
      },
      beginDateMenu: false,
      date: vm
        .$moment()
        .startOf("month")
        .format("YYYY-MM"),
      table: {
        loading: false,
        data: [],
        criteria: {
          employees: [],
          beginDate: vm
            .$moment()
            .startOf("month")
            .format("YYYY-MM-DD"),
          endDate: vm
            .$moment()
            .endOf("month")
            .format("YYYY-MM-DD")
        },
        options: {},
        pagination: {
          rowsPerPage: 50,
          sortBy: "requestDateTime",
          descending: true
        },
        totalItems: 0,
        totalPages: 0,
        selected: [],
        headers: [
          {
            text: vm.$t("global.employee"),
            align: "left",
            sortable: false,
            value: "firstName",
            class: ["primary--text text-lg-left"]
          },

          {
            text: vm.$t("leave.type"),
            align: "left",
            sortable: false,
            value: vm.$store.state.auth.language === "tr" ? "leaveType.name" : "leaveType.englishName",
            class: ["primary--text text-lg-left"]
          },

          {
            text: vm.$t("leave.begin_date"),
            align: "left",
            sortable: false,
            value: "",
            class: ["primary--text text-lg-left"]
          },
          {
            text: vm.$t("leave.end_date"),
            align: "left",
            sortable: false,
            value: "",
            class: ["primary--text text-lg-left"]
          },

          {
            text: vm.$t("leave.request_time"),
            align: "left",
            sortable: false,
            value: "requestDateTime",
            class: ["primary--text text-lg-left"]
          },
          {
            text: vm.$t("leave.approve_or_reject"),
            align: "left",
            sortable: false,
            value: "",
            class: ["primary--text text-lg-left"]
          },
          {
            text: vm.$t("leave.cancels.leave_cancel"),
            align: "left",
            sortable: false,
            value: "",
            class: ["primary--text text-lg-left"]
          }
        ]
      }
    }),
    watch: {
      "table.options": {
        handler(options) {
          let { itemsPerPage, page } = options;
          this.table.pagination.rowsPerPage = itemsPerPage;
          this.table.pagination.page = page;
          this.table.criteria.employees = [this.$store.state.auth.user.id];
          this.fetchItems();
        },
        deep: true
      }
    },
    computed: {
      formattedMonth() {
        return this.$moment(this.date).format("MMMM");
      }
    },
    methods: {
      onClickLeaveCancel(leave) {
        this.cancelDialog.data = leave;
        this.cancelDialog.status = true;
      },
      onCloseLeaveDialog() {
        this.cancelDialog.data = null;
        this.cancelDialog.status = false;
      },
      onSuccessLeaveCancel() {
        this.onCloseLeaveDialog();
        this.onRefreshData();
      },
      onRefreshData() {
        this.fetchItems();
      },
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: LEAVE_APPROVAL,
            variables: {
              id: this.$store.state.auth.user.id
            }
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              data.employee.pendingApprovalLeaveRequests.sort((a, b) =>
                this.$moment(b.leaveRequest.requestDateTime).diff(
                  this.$moment(a.leaveRequest.requestDateTime),
                  "seconds"
                )
              );
              this.table.data = data.employee.pendingApprovalLeaveRequests || [];
              this.table.totalItems = data.employee.pendingApprovalLeaveRequests.length || 0;
              this.table.totalPages = data.employee.pendingApprovalLeaveRequests.length || 0;
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => (this.table.loading = false));
      },
      getLeaveStatusIconText(leaveStatus) {
        return this.$t(`leave_request_status.${leaveStatus}`);
      },
      getLeaveStatusIcon(leaveStatus) {
        let icon = "";
        switch (leaveStatus) {
          case "PENDING":
            icon = "hourglass_empty";
            break;
          case "APPROVED":
            icon = "done_all";
            break;
          case "CANCELLED":
            icon = "clear";
            break;
          case "NEED_EXTRA_DETAIL":
            icon = "info";
            break;
          case "DENIED":
            icon = "block";
            break;
          default:
            icon = "help";
            break;
        }
        return icon;
      },
      getLeaveStatusIconColor(status) {
        let color = "";
        switch (status) {
          case "PENDING":
            color = "orange";
            break;
          case "APPROVED":
            color = "green";
            break;
          case "CANCELLED":
            color = "grey";
            break;
          case "NEED_EXTRA_DETAIL":
            color = "blue";
            break;
          case "DENIED":
            color = "red";
            break;
          default:
            color = "black";
            break;
        }
        return color;
      },
      onClickLeaveApproval(leaveRequest) {
        this.dialog.data = leaveRequest;
        this.dialog.status = true;
      },
      onCloseDialog() {
        this.dialog.data = null;
        this.dialog.status = false;
      },
      onSuccessApproval() {
        this.onCloseDialog();
        this.onRefreshData();
      },
      getTotalLeaveDay(leaveDays) {
        const isHourly = leaveDays.filter(item => item.duration === "HOURLY");

        if (isHourly.length) {
          return leaveDays.reduce((acc, item) => (acc += item.lengthInMinutes / 60), 0) + " " + this.$t("global.hour");
        } else {
          return leaveDays.length + " " + this.$t("global.day");
        }
      },
      getIsHourlyLeaveBeginTime(leaveDays) {
        const isHourly = leaveDays.filter(item => item.duration === "HOURLY");
        if (isHourly.length) {
          return this.$moment(leaveDays[0].fromTime, "HH:mm").format("HH:mm");
        }
      },
      getIsHourlyLeaveEndTime(leaveDays) {
        const isHourly = leaveDays.filter(item => item.duration === "HOURLY");
        if (isHourly.length) {
          return this.$moment(leaveDays[0].fromTime, "HH:mm")
            .add(isHourly[0].lengthInMinutes, "minutes")
            .format("HH:mm");
        }
      }
    }
  };
</script>

<style scoped></style>
